// @ts-ignore
import React from 'react';

import Layout from '../components/Layout';
import {graphql} from "gatsby";
import { IGatsbyImageData } from 'gatsby-plugin-image'

import Gallery from '@browniebroke/gatsby-image-gallery'

interface ImageSharpEdge {
  node: {
    childImageSharp: {
      thumb: IGatsbyImageData
      full: IGatsbyImageData
    }
  }
}

interface PageProps {
  data: {
    images: {
      edges: ImageSharpEdge[]
    }
  }
}

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const images = data.images.edges.map(({node}, index) => ({
    ...node.childImageSharp,
  }));
  return (
      <Layout>
        <div id="main">
          <div className="box container">
            <header>
              <h2>Jarenlange ervaring</h2>
            </header>
            <section>
              <header>
                <p>Met jarenlange ervaring als elektricien breng ik een schat aan kennis en skills naar elk project. Of het nu gaat om het netjes bedraden van huishoudelijke elektriciteitskasten of het aanpakken van ingewikkelde industriële systemen, ik zet kwaliteit en veiligheid altijd voorop.</p>
              </header>
            <Gallery images={images} />
            </section>

          </div>
        </div>
      </Layout>
  )
}

export const pageQuery = graphql`
  query ImagesForExperienceGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "ervaring" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default IndexPage;
